define("ember-prop-modifier/modifiers/prop", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _emberModifier.modifier)(function prop(element, _, named) {
    for (let key in named) {
      element[key] = named[key];
    }
  });
  _exports.default = _default;
});