define("ember-content-editable/components/content-editable", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-down-event-binding --}}
  {{!-- template-lint-disable no-invalid-interactive --}}
  <div {{on 'keydown' this.onKeyDown}}
       {{on 'paste' this.onPaste}}
       {{content-editable value=@value onChange=@onChange placeholder=@placeholder autofocus=@autofocus disabled=@disabled}}
       class={{if @clearPlaceholderOnFocus 'clear-on-focus'}}
       ...attributes></div>
  */
  {
    "id": "T5vo4ocr",
    "block": "[[[11,0],[16,0,[52,[30,1],\"clear-on-focus\"]],[17,2],[4,[38,1],[\"keydown\",[30,0,[\"onKeyDown\"]]],null],[4,[38,1],[\"paste\",[30,0,[\"onPaste\"]]],null],[4,[38,2],null,[[\"value\",\"onChange\",\"placeholder\",\"autofocus\",\"disabled\"],[[30,3],[30,4],[30,5],[30,6],[30,7]]]],[12],[13]],[\"@clearPlaceholderOnFocus\",\"&attrs\",\"@value\",\"@onChange\",\"@placeholder\",\"@autofocus\",\"@disabled\"],false,[\"if\",\"on\",\"content-editable\"]]",
    "moduleName": "ember-content-editable/components/content-editable.hbs",
    "isStrictMode": false
  });
  const alwaysAllowedKeys = [8,
  // backspace
  9,
  // tab
  12,
  // clear
  16,
  // shift
  17,
  // control
  18,
  // option
  20,
  // caps
  27,
  // escape
  35,
  // end
  36,
  // home
  37,
  // left arrow
  38,
  // up arrow
  39,
  // right arrow
  40,
  // down arrow
  46,
  // delete
  91,
  // meta left
  93 // meta right
  ];
  let ContentEditableComponent = (_class = class ContentEditableComponent extends _component2.default {
    onKeyDown(event) {
      const isEnter = event.keyCode === 13;
      const isEscape = event.keyCode === 27;
      if (isEnter && this.args.onEnter) {
        this.args.onEnter(event);
      }
      if (isEscape && this.args.onEscape) {
        this.args.onEscape(event);
      }
      if (this.args.maxlength && this.args.maxlength <= this.args.value?.length && !alwaysAllowedKeys.includes(event.keyCode)) {
        event.preventDefault();
        if (this.args.onLengthExceeded) {
          this.args.onLengthExceeded(this.args.value?.length);
        }
        return;
      }
      if (!this.args.allowNewlines && isEnter) {
        event.preventDefault();
        return;
      }
      if (this.args.onKey) {
        this.args.onKey(event);
      }
    }
    getSelectionLength() {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.endOffset - range.startOffset;
      }
      return 0;
    }
    onPaste(event) {
      event.preventDefault();
      // replace any html formatted text with its plain text equivalent
      let text = '';
      if (event.clipboardData) {
        text = event.clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text');
      }

      // check max length
      if (this.args.maxlength) {
        // a selection will be replaced. substract the length of the selection from the total length
        const selectionLength = this.getSelectionLength();
        const afterPasteLength = text.length + this.args.value.length - selectionLength;
        if (afterPasteLength > this.args.maxlength) {
          if (this.args.onLengthExceeded) {
            this.args.onLengthExceeded(afterPasteLength);
          }
          return false;
        }
      }
      crossSupportInsertText(text);
      if (this.args.onPaste) {
        this.args.onPaste(text);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPaste", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPaste"), _class.prototype)), _class);
  _exports.default = ContentEditableComponent;
  function crossSupportInsertText(text) {
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      const textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);
      const selection = document.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContentEditableComponent);
});