define("ember-toggle/components/x-toggle-label", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @show}}
    <label
      for={{@switchId}}
      class="toggle-text toggle-prefix {{this.type}}-label"
      ...attributes
      {{on 'click' this.clickLabel}}
    >
      {{#if (has-block)}}
        {{yield @label}}
      {{else}}
        {{@label}}
      {{/if}}
    </label>
  {{/if}}
  */
  {
    "id": "OV1ibfpw",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"label\"],[16,\"for\",[30,2]],[16,0,[29,[\"toggle-text toggle-prefix \",[30,0,[\"type\"]],\"-label\"]]],[17,3],[4,[38,1],[\"click\",[30,0,[\"clickLabel\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"      \"],[18,5,[[30,4]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@show\",\"@switchId\",\"&attrs\",\"@label\",\"&default\"],false,[\"if\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-toggle/components/x-toggle-label.hbs",
    "isStrictMode": false
  });
  let XToggleLabel = (_class = class XToggleLabel extends _component2.default {
    get type() {
      return this.args.value ? 'on' : 'off';
    }
    clickLabel(e) {
      e.stopPropagation();
      e.preventDefault();
      this.args.sendToggle(this.args.value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clickLabel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clickLabel"), _class.prototype)), _class);
  _exports.default = XToggleLabel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, XToggleLabel);
});