define("ember-modifier/-private/functional/modifier-manager", ["exports", "@ember/modifier", "ember-modifier/-private/compat"], function (_exports, _modifier, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const MODIFIER_ELEMENTS = new WeakMap();
  const MODIFIER_TEARDOWNS = new WeakMap();
  function teardown(modifier) {
    const teardown = MODIFIER_TEARDOWNS.get(modifier);
    if (teardown && typeof teardown === 'function') {
      teardown();
    }
  }
  function setup(modifier, element, args) {
    const {
      positional,
      named
    } = args;
    const teardown = modifier(element, positional, named);
    MODIFIER_TEARDOWNS.set(modifier, teardown);
  }
  class FunctionalModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)(true ? '3.22' : '3.13'));
    }
    createModifier(factoryOrClass) {
      const Modifier = (0, _compat.isFactory)(factoryOrClass) ? factoryOrClass.class : factoryOrClass;

      // This looks superfluous, but this is creating a new instance
      // of a function -- this is important so that each instance of the
      // created modifier can have its own state which is stored in
      // the MODIFIER_ELEMENTS and MODIFIER_TEARDOWNS WeakMaps
      return function () {
        return Modifier(...arguments);
      };
    }
    installModifier(modifier, element, args) {
      MODIFIER_ELEMENTS.set(modifier, element);
      if (true) {
        (0, _compat.consumeArgs)(args);
      }
      setup(modifier, element, args);
    }
    updateModifier(modifier, args) {
      const element = MODIFIER_ELEMENTS.get(modifier);
      teardown(modifier);
      if (true) {
        (0, _compat.consumeArgs)(args);
      }
      setup(modifier, element, args);
    }
    destroyModifier(modifier) {
      teardown(modifier);
    }
  }
  var _default = new FunctionalModifierManager();
  _exports.default = _default;
});